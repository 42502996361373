import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  picList = {
    info: [
      {
        id: '1',
        type_ch: '劇照',
        type_en: 'film stills',
        folder_image: '1_drama_photos',
        tree: [
          {
            id: '1-1',
            title: '院線電影劇照-杏林醫院',
            image: '1_1',
            folder_image: '1_drama_photos',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}, {img: '7'}, {img: '8'}, {img: '9'}, {img: '10'},
              {img: '11'}, {img: '12'}, {img: '13'}, {img: '14'}, {img: '15'},
              {img: '16'}, {img: '17'}, {img: '18'}, {img: '19'}, {img: '20'},
              {img: '21'}, {img: '22'}, {img: '23'}, {img: '24'}, {img: '25'},
              {img: '26'}, {img: '27'}, {img: '28'}, {img: '29'}, {img: '30'},
              {img: '31'}, {img: '32'}, {img: '33'}, {img: '34'}, {img: '35'},
              {img: '36'}, {img: '37'}, {img: '38'}, {img: '39'}, {img: '40'},
              {img: '41'}, {img: '42'}, {img: '43'}, {img: '44'}, {img: '45'},
              {img: '46'}
            ]
          },
          {
            id: '1-2',
            title: '第一廣劇照-金鐘劇情長片',
            image: '1_2',
            folder_image: '1_drama_photos',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}, {img: '7'}, {img: '8'}, {img: '9'}, {img: '10'},
              {img: '11'}, {img: '12'}, {img: '13'}, {img: '14'}, {img: '15'},
              {img: '16'}, {img: '17'}, {img: '18'}
            ]
          },
          {
            id: '1-3',
            title: '手機-高雄電影節劇照',
            image: '1_3',
            folder_image: '1_drama_photos',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}, {img: '7'}, {img: '8'}
            ]
          },
          {
            id: '1-4',
            title: '海浪',
            image: '1_4',
            folder_image: '1_drama_photos',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}, {img: '7'}, {img: '8'}, {img: '9'}, {img: '10'},
              {img: '11'}, {img: '12'}, {img: '13'}, {img: '14'}
            ]
          }
        ]
      },
      {
        id: '2',
        type_ch: '側拍',
        type_en: 'behind the scenes',
        folder_image: '2_side_shot',
        tree: [
          {
            id: '2-1',
            title: 'Vogue 採訪',
            image: '2_1',
            folder_image: '2_side_shot',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}, {img: '7'}, {img: '8'}, {img: '9'}, {img: '10'},
              {img: '11'}, {img: '12'}
            ]
          }
        ]
      },
      {
        id: '3',
        type_ch: '形象宣傳',
        type_en: 'publicity photo',
        folder_image: '3_publicity',
        tree: [
          {
            id: '3-1',
            title: '品牌形象',
            image: '3_1',
            folder_image: '3_publicity',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}
            ]
          },
          {
            id: '3-2',
            title: '樂團宣傳',
            image: '3_2',
            folder_image: '3_publicity',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}, {img: '7'}, {img: '8'}, {img: '9'}, {img: '10'},
              {img: '11'}, {img: '12'}, {img: '13'}, {img: '14'}, {img: '15'}
            ]
          }
        ]
      },
      {
        id: '4',
        type_ch: '型錄',
        type_en: 'Catalog',
        folder_image: '4_catalog',
        tree: [
          {
            id: '4-1',
            title: '服裝時裝',
            image: '4_1',
            folder_image: '4_catalog',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}, {img: '7'}, {img: '8'}, {img: '9'}, {img: '10'},
              {img: '11'}, {img: '12'}, {img: '13'}, {img: '14'}, {img: '15'},
              {img: '16'}, {img: '17'}, {img: '18'}
            ]
          }
        ]
      },
      {
        id: '5',
        type_ch: '網拍',
        type_en: 'Online Auction Photos',
        folder_image: '5_web_shoot',
        tree: [
          {
            id: '5-1',
            title: '網拍',
            image: '5_1',
            folder_image: '5_web_shoot',
            tree: [
              {img: '1'}, {img: '2'}, {img: '3'}, {img: '4'}, {img: '5'},
              {img: '6'}, {img: '7'}, {img: '8'}
            ]
          }
        ]
      }
    ]
  };

  videoList = {
    info: [
      {
        id: '1',
        title: 'Image Propaganda',
        tree: [
          {
            video_url: 'https://www.youtube.com/embed/lh4Y2AiZhyc?autoplay=1',
            video_name: 'COACH 熱氣球嘉年華',
            video_img: '1_166.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/4cZFeTKogsk?autoplay=1',
            video_name: 'PROMESSA',
            video_img: '1_177.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/iE7jObGEFtA?autoplay=1',
            video_name: 'AAL 墨海樓 倫敦亞洲藝術國際展',
            video_img: '1_18.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/Hg2Jn3UoEJM?autoplay=1',
            video_name: 'PORTER',
            video_img: '1_1.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/-0gYe2HTrSk?autoplay=1',
            video_name: 'Tiger Beer',
            video_img: '1_2.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/aL4X2xQ1ggk?autoplay=1',
            video_name: '天使娜拉',
            video_img: '1_3.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/AN4_-XkmHD0?autoplay=1',
            video_name: 'iROO 形象影片-護理加油',
            video_img: '1_44.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/vamOvG9SG6A?autoplay=1',
            video_name: 'Foodpanda圓夢計畫',
            video_img: '1_5.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/AIyW44WoQkg?autoplay=1',
            video_name: '澎湖國際海上花火節',
            video_img: '1_6.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/dakN5GtnGLE?autoplay=1',
            video_name: '小伴桌品牌宣傳片',
            video_img: '1_7.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/I9Q7d3hkQao?autoplay=1',
            video_name: '開飯食堂',
            video_img: '1_8.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/djIEM5aMMVQ?autoplay=1',
            video_name: '真珠臺菜餐廳',
            video_img: '1_9.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/juvvLE1oqb8?autoplay=1',
            video_name: 'FEDE 春夏形象',
            video_img: '1_10.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/9Bswi6iqkN0?autoplay=1',
            video_name: 'FEDE x Zone7',
            video_img: '1_11.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/PRCqm_x18ig?autoplay=1',
            video_name: '欣蘭化妝品推廣形象',
            video_img: '1_122.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/dTS1w4tbrww?autoplay=1',
            video_name: '未來美 水楊酸產品',
            video_img: '1_13.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/YAGbYZOGkyU?autoplay=1',
            video_name: '未來美 氣墊霜系列',
            video_img: '1_14.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/SyUNqyknKBU?autoplay=1',
            video_name: 'VEMAR Image',
            video_img: '1_15.jpg'
          },
        ]
      },
      {
        id: '2',
        title: 'Fashion Event',
        tree: [
          {
            video_url: 'https://www.youtube.com/embed/_zTo5W8xYNw?autoplay=1',
            video_name: 'AP x MUSIC',
            video_img: '2_21.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/vFzYdf0IqD0?autoplay=1',
            video_name: 'GUCCI FW24',
            video_img: '2_22.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/1y3ebqgtrV8?autoplay=1',
            video_name: 'FENDI-RAIN',
            video_img: '2_23.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/aEetow4Pbcw?autoplay=1',
            video_name: 'GUCCI 101開幕活動- 安孝燮',
            video_img: '2_1.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/ryTBFH8DPRQ?autoplay=1',
            video_name: 'ADV_LABEL- DARA',
            video_img: '2_4.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/WSubJa5dOGk?autoplay=1',
            video_name: 'SWAROVSKI Extravagant Wonder- Nana林珍娜',
            video_img: '2_2.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/Sa6QCvQDFBc?autoplay=1',
            video_name: 'Bottega Veneta-路雲(金錫佑)',
            video_img: '2_24.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/PUazXmj7BjI?autoplay=1',
            video_name: 'TORY BURCH- ITZY*禮志',
            video_img: '2_25.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/e4atOLhfrKg?autoplay=1',
            video_name: 'RADO-池昌旭',
            video_img: '2_266.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/1DLm7jqUWxs?autoplay=1',
            video_name: '未來美 八週年-宋慧喬',
            video_img: '2_27.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/ElCwW5fbvag?autoplay=1',
            video_name: 'FENDI微風開幕',
            video_img: '2_5.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/eWIeU51SluU?autoplay=1',
            video_name: 'GUCCI Gift Giving Event',
            video_img: '2_6.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/6KwQi__gpYE?autoplay=1',
            video_name: '台南時裝週FW23',
            video_img: '2_7.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/gjDixJb7PA0?autoplay=1',
            video_name: '台北時裝週SS24',
            video_img: '2_8.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/IRFdR5oVONc?autoplay=1',
            video_name: 'BOHEM x S2O Taiwan',
            video_img: '2_199.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/Z2YtM3fPOoc?autoplay=1',
            video_name: 'New Balance品牌旗艦店開幕',
            video_img: '2_10.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/wH4ioJls-t8?autoplay=1',
            video_name: '海尼根星釀特展',
            video_img: '2_11.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/pgprsYJ0LtE?autoplay=1',
            video_name: 'NEW ERA 59 FIFTY',
            video_img: '2_20.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/qJQqlD7V8jY?autoplay=1',
            video_name: 'FOCASA馬戲藝術節',
            video_img: '2_13.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/Iyz8xoj6KD8?autoplay=1',
            video_name: '麥當勞歡樂送 x WirForce',
            video_img: '2_14.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/o1hHEAh0HI8?autoplay=1',
            video_name: 'Hennessy x NBA- Allen Iverson',
            video_img: '2_15.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/cEpdIFf3uI4?autoplay=1',
            video_name: 'Audemars Piguet GOLF',
            video_img: '2_16.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/amC8uKf21so?autoplay=1',
            video_name: '法拉利- 活動宣傳片',
            video_img: '2_17.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/94Pol0n3ERg?autoplay=1',
            video_name: 'Casetify品牌概念店',
            video_img: '2_18.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/RKb6qWnhszk?autoplay=1',
            video_name: 'Hermès Kiosque Event',
            video_img: '2_28.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/3oFl5B2h5BE?autoplay=1',
            video_name: 'COACH Balloon Festival',
            video_img: '2_30.jpg'
          },
        ]
      },
      {
        id: '3',
        title: 'Case Closed',
        tree: [
          {
            video_url: 'https://www.youtube.com/embed/rxPiVzbLinA?autoplay=1',
            video_name: '文策院-2022元宇宙',
            video_img: '3_1.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/vQqgo9QJHKE?autoplay=1',
            video_name: 'New Balance國際開幕影片-台灣篇',
            video_img: '3_2.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/h4wGRCO3qm4?autoplay=1',
            video_name: 'Tiger Beer年度影片製作',
            video_img: '3_3.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/-plkdqtLMnM?autoplay=1',
            video_name: '海尼根0 0 Showcase',
            video_img: '3_4.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/KcEOUA9PzaE?autoplay=1',
            video_name: 'COACH年度影片',
            video_img: '3_5.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/h92Dvs2qy0M?autoplay=1',
            video_name: 'AEDBED活動結案影片',
            video_img: '3_6.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/GFeKwTjkK1w?autoplay=1',
            video_name: 'She_s Mercedes-Benz',
            video_img: '3_7.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/PWHu30t06zQ?autoplay=1',
            video_name: 'GUCCI年度結案影片',
            video_img: '3_8.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/6p6wn-6269E?autoplay=1',
            video_name: '肌膚之鑰CPB Trade Presentation',
            video_img: '3_9.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/jsjhQEb8JdE?autoplay=1',
            video_name: 'Christian Louboutin Event',
            video_img: '3_10.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/0mjNR5O537k?autoplay=1',
            video_name: 'CHANEL年終大會頒獎典禮影片',
            video_img: '3_11.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/xaiTs4x1J2E?autoplay=1',
            video_name: 'NB年末動畫_Taiwan',
            video_img: '3_12.jpg'
          },
        ]
      },
      {
        id: '4',
        title: 'Highlights',
        tree: [
          {
            video_url: 'https://www.youtube.com/embed/hLpnSIElqrQ?autoplay=1',
            video_name: '李沐Ralph Lauren',
            video_img: '4_1.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/rxYQoYFf76k?autoplay=1',
            video_name: '謝欣穎Ralph Lauren',
            video_img: '4_2.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/eHhtdt6E9GQ?autoplay=1',
            video_name: 'JC Lin x三星手機',
            video_img: '4_3.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/YtGJAYY3DmA?autoplay=1',
            video_name: '林柏宏愛迪達10月預告',
            video_img: '4_133.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/tYWAr1QKpRs?autoplay=1',
            video_name: '林柏宏愛迪達11月預告',
            video_img: '4_144.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/-_ZPmmHBHT0?autoplay=1',
            video_name: '林柏宏愛迪達12月',
            video_img: '4_155.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/7Gt1C0W99c4?autoplay=1',
            video_name: 'RAIN高雄演唱會',
            video_img: '4_44.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/AOiO_2JyPX4?autoplay=1',
            video_name: 'Karencici Onitsuka Tiger',
            video_img: '4_5.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/DoASi-5d4-w?autoplay=1',
            video_name: 'Casetify空景',
            video_img: '4_6.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/-L696YONRPw?autoplay=1',
            video_name: 'AP x MARVEL OOH',
            video_img: '4_7.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/YAPzUzSCoPY?autoplay=1',
            video_name: 'GUCCI 牛仔',
            video_img: '4_8.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/uH3Y2tHAzg8?autoplay=1',
            video_name: 'LuLu GUCCI CNY',
            video_img: '4_9.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/bFm4CBXlLBc?autoplay=1',
            video_name: '海尼根0 0 x CPBL',
            video_img: '4_10.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/ot4meoBpC-U?autoplay=1',
            video_name: '林宥嘉MV花絮',
            video_img: '4_11.jpg'
          },
          {
            video_url: 'https://www.youtube.com/embed/CKF8HNrcL4s?autoplay=1',
            video_name: '手遊 滅世錄',
            video_img: '4_12.jpg'
          },
        ]
      },
    ]
  };

  constructor() { }

}
